/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseTemplateDto } from '../../models/response-template-dto';

export interface GetStaffTemplates$Params {

/**
 * the template category id ->   1:ASD    2:IV/free customers   3:nationwide templates
 */
  templateCategories?: Array<'ASD' | 'INDIVIDUAL' | 'NATIONWIDE'>;

/**
 * the field of care id
 */
  fieldOfCareIds?: Array<number>;

/**
 * the document type id
 */
  documentTypeIds?: Array<number>;

/**
 * search for the given name of a document
 */
  fileName?: string;

/**
 * range filter for the upload date [from/to]
 */
  uploadDateFromTo?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export function getStaffTemplates(http: HttpClient, rootUrl: string, params?: GetStaffTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseTemplateDto>> {
  const rb = new RequestBuilder(rootUrl, getStaffTemplates.PATH, 'get');
  if (params) {
    rb.query('templateCategories', params.templateCategories, {});
    rb.query('fieldOfCareIds', params.fieldOfCareIds, {});
    rb.query('documentTypeIds', params.documentTypeIds, {});
    rb.query('fileName', params.fileName, {});
    rb.query('uploadDateFromTo', params.uploadDateFromTo, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseTemplateDto>;
    })
  );
}

getStaffTemplates.PATH = '/documents/templates';
