/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AsiSvgInfoDto } from '../models/asi-svg-info-dto';
import { BlockedDayDto } from '../models/blocked-day-dto';
import { blockedDays } from '../fn/blocked-days-controller/blocked-days';
import { BlockedDays$Params } from '../fn/blocked-days-controller/blocked-days';
import { BlockedDaysOfYearDto } from '../models/blocked-days-of-year-dto';
import { blockedDaysSvgInfos } from '../fn/blocked-days-controller/blocked-days-svg-infos';
import { BlockedDaysSvgInfos$Params } from '../fn/blocked-days-controller/blocked-days-svg-infos';
import { createBlockedDay } from '../fn/blocked-days-controller/create-blocked-day';
import { CreateBlockedDay$Params } from '../fn/blocked-days-controller/create-blocked-day';
import { createPublicHolidays } from '../fn/blocked-days-controller/create-public-holidays';
import { CreatePublicHolidays$Params } from '../fn/blocked-days-controller/create-public-holidays';
import { deleteBlockedDays } from '../fn/blocked-days-controller/delete-blocked-days';
import { DeleteBlockedDays$Params } from '../fn/blocked-days-controller/delete-blocked-days';
import { deletePublicHoliday } from '../fn/blocked-days-controller/delete-public-holiday';
import { DeletePublicHoliday$Params } from '../fn/blocked-days-controller/delete-public-holiday';

@Injectable({ providedIn: 'root' })
export class BlockedDaysControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `blockedDays()` */
  static readonly BlockedDaysPath = '/blocked-days';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockedDays()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockedDays$Response(params?: BlockedDays$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BlockedDaysOfYearDto>>> {
    return blockedDays(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `blockedDays$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockedDays(params?: BlockedDays$Params, context?: HttpContext): Observable<Array<BlockedDaysOfYearDto>> {
    return this.blockedDays$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BlockedDaysOfYearDto>>): Array<BlockedDaysOfYearDto> => r.body)
    );
  }

  /** Path part for operation `createBlockedDay()` */
  static readonly CreateBlockedDayPath = '/blocked-days';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBlockedDay()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBlockedDay$Response(params: CreateBlockedDay$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BlockedDayDto>>> {
    return createBlockedDay(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBlockedDay$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBlockedDay(params: CreateBlockedDay$Params, context?: HttpContext): Observable<Array<BlockedDayDto>> {
    return this.createBlockedDay$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BlockedDayDto>>): Array<BlockedDayDto> => r.body)
    );
  }

  /** Path part for operation `deleteBlockedDays()` */
  static readonly DeleteBlockedDaysPath = '/blocked-days';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBlockedDays()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteBlockedDays$Response(params: DeleteBlockedDays$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteBlockedDays(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBlockedDays$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteBlockedDays(params: DeleteBlockedDays$Params, context?: HttpContext): Observable<void> {
    return this.deleteBlockedDays$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createPublicHolidays()` */
  static readonly CreatePublicHolidaysPath = '/blocked-days/public-holidays';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPublicHolidays()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPublicHolidays$Response(params: CreatePublicHolidays$Params, context?: HttpContext): Observable<StrictHttpResponse<BlockedDaysOfYearDto>> {
    return createPublicHolidays(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPublicHolidays$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPublicHolidays(params: CreatePublicHolidays$Params, context?: HttpContext): Observable<BlockedDaysOfYearDto> {
    return this.createPublicHolidays$Response(params, context).pipe(
      map((r: StrictHttpResponse<BlockedDaysOfYearDto>): BlockedDaysOfYearDto => r.body)
    );
  }

  /** Path part for operation `deletePublicHoliday()` */
  static readonly DeletePublicHolidayPath = '/blocked-days/public-holidays';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePublicHoliday()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deletePublicHoliday$Response(params: DeletePublicHoliday$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deletePublicHoliday(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePublicHoliday$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deletePublicHoliday(params: DeletePublicHoliday$Params, context?: HttpContext): Observable<void> {
    return this.deletePublicHoliday$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `blockedDaysSvgInfos()` */
  static readonly BlockedDaysSvgInfosPath = '/blocked-days/svgs';

  /**
   * Get all SVG Infos, for admins. Returns only the user SVG for others
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `blockedDaysSvgInfos()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockedDaysSvgInfos$Response(params?: BlockedDaysSvgInfos$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AsiSvgInfoDto>>> {
    return blockedDaysSvgInfos(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all SVG Infos, for admins. Returns only the user SVG for others
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `blockedDaysSvgInfos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  blockedDaysSvgInfos(params?: BlockedDaysSvgInfos$Params, context?: HttpContext): Observable<Array<AsiSvgInfoDto>> {
    return this.blockedDaysSvgInfos$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AsiSvgInfoDto>>): Array<AsiSvgInfoDto> => r.body)
    );
  }

}
