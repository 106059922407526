/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteTemplate } from '../fn/template-controller/delete-template';
import { DeleteTemplate$Params } from '../fn/template-controller/delete-template';
import { DocumentLinkDto } from '../models/document-link-dto';
import { DocumentSasUrlDto } from '../models/document-sas-url-dto';
import { downloadUrlBlankTemplate } from '../fn/template-controller/download-url-blank-template';
import { DownloadUrlBlankTemplate$Params } from '../fn/template-controller/download-url-blank-template';
import { generateTemplatePreviewByTemplateFile } from '../fn/template-controller/generate-template-preview-by-template-file';
import { GenerateTemplatePreviewByTemplateFile$Params } from '../fn/template-controller/generate-template-preview-by-template-file';
import { generateTemplatePreviewByUuid } from '../fn/template-controller/generate-template-preview-by-uuid';
import { GenerateTemplatePreviewByUuid$Params } from '../fn/template-controller/generate-template-preview-by-uuid';
import { getAllChecklists } from '../fn/template-controller/get-all-checklists';
import { GetAllChecklists$Params } from '../fn/template-controller/get-all-checklists';
import { getCustomerTemplates } from '../fn/template-controller/get-customer-templates';
import { GetCustomerTemplates$Params } from '../fn/template-controller/get-customer-templates';
import { getStaffTemplates } from '../fn/template-controller/get-staff-templates';
import { GetStaffTemplates$Params } from '../fn/template-controller/get-staff-templates';
import { ResponseTemplateDto } from '../models/response-template-dto';
import { TemplateDto } from '../models/template-dto';
import { TemplatePreviewDto } from '../models/template-preview-dto';
import { uploadAsdTemplate } from '../fn/template-controller/upload-asd-template';
import { UploadAsdTemplate$Params } from '../fn/template-controller/upload-asd-template';
import { uploadIvTemplate } from '../fn/template-controller/upload-iv-template';
import { UploadIvTemplate$Params } from '../fn/template-controller/upload-iv-template';
import { uploadNationwideTemplate } from '../fn/template-controller/upload-nationwide-template';
import { UploadNationwideTemplate$Params } from '../fn/template-controller/upload-nationwide-template';


/**
 * Provides operations to manage document templates.
 */
@Injectable({ providedIn: 'root' })
export class TemplateControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `uploadNationwideTemplate()` */
  static readonly UploadNationwideTemplatePath = '/documents/templates/nationwide';

  /**
   * Upload nationwide template document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadNationwideTemplate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadNationwideTemplate$Response(params: UploadNationwideTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateDto>> {
    return uploadNationwideTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload nationwide template document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadNationwideTemplate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadNationwideTemplate(params: UploadNationwideTemplate$Params, context?: HttpContext): Observable<TemplateDto> {
    return this.uploadNationwideTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateDto>): TemplateDto => r.body)
    );
  }

  /** Path part for operation `uploadIvTemplate()` */
  static readonly UploadIvTemplatePath = '/documents/templates/iv';

  /**
   * Upload an IV template document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadIvTemplate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadIvTemplate$Response(params: UploadIvTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateDto>> {
    return uploadIvTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload an IV template document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadIvTemplate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadIvTemplate(params: UploadIvTemplate$Params, context?: HttpContext): Observable<TemplateDto> {
    return this.uploadIvTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateDto>): TemplateDto => r.body)
    );
  }

  /** Path part for operation `uploadAsdTemplate()` */
  static readonly UploadAsdTemplatePath = '/documents/templates/asd';

  /**
   * Upload an ASD template document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadAsdTemplate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAsdTemplate$Response(params: UploadAsdTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateDto>> {
    return uploadAsdTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload an ASD template document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadAsdTemplate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAsdTemplate(params: UploadAsdTemplate$Params, context?: HttpContext): Observable<TemplateDto> {
    return this.uploadAsdTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateDto>): TemplateDto => r.body)
    );
  }

  /** Path part for operation `generateTemplatePreviewByTemplateFile()` */
  static readonly GenerateTemplatePreviewByTemplateFilePath = '/customers/{customerId}/complete-template';

  /**
   * Create a preview document using a template referenced by a template document id and using customer data to fill the template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateTemplatePreviewByTemplateFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  generateTemplatePreviewByTemplateFile$Response(params: GenerateTemplatePreviewByTemplateFile$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplatePreviewDto>> {
    return generateTemplatePreviewByTemplateFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a preview document using a template referenced by a template document id and using customer data to fill the template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateTemplatePreviewByTemplateFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  generateTemplatePreviewByTemplateFile(params: GenerateTemplatePreviewByTemplateFile$Params, context?: HttpContext): Observable<TemplatePreviewDto> {
    return this.generateTemplatePreviewByTemplateFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplatePreviewDto>): TemplatePreviewDto => r.body)
    );
  }

  /** Path part for operation `generateTemplatePreviewByUuid()` */
  static readonly GenerateTemplatePreviewByUuidPath = '/customers/{customerId}/complete-template-uuid/{templateUUID}';

  /**
   * Create a preview document using a template referenced by uuid and using customer data to fill the template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateTemplatePreviewByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateTemplatePreviewByUuid$Response(params: GenerateTemplatePreviewByUuid$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplatePreviewDto>> {
    return generateTemplatePreviewByUuid(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a preview document using a template referenced by uuid and using customer data to fill the template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateTemplatePreviewByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateTemplatePreviewByUuid(params: GenerateTemplatePreviewByUuid$Params, context?: HttpContext): Observable<TemplatePreviewDto> {
    return this.generateTemplatePreviewByUuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplatePreviewDto>): TemplatePreviewDto => r.body)
    );
  }

  /** Path part for operation `downloadUrlBlankTemplate()` */
  static readonly DownloadUrlBlankTemplatePath = '/download/{uuid}';

  /**
   * provides download url for blank templates.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadUrlBlankTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadUrlBlankTemplate$Response(params: DownloadUrlBlankTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentSasUrlDto>> {
    return downloadUrlBlankTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * provides download url for blank templates.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadUrlBlankTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadUrlBlankTemplate(params: DownloadUrlBlankTemplate$Params, context?: HttpContext): Observable<DocumentSasUrlDto> {
    return this.downloadUrlBlankTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentSasUrlDto>): DocumentSasUrlDto => r.body)
    );
  }

  /** Path part for operation `getStaffTemplates()` */
  static readonly GetStaffTemplatesPath = '/documents/templates';

  /**
   * Get document templates in the staff context.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStaffTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStaffTemplates$Response(params?: GetStaffTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseTemplateDto>> {
    return getStaffTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get document templates in the staff context.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStaffTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStaffTemplates(params?: GetStaffTemplates$Params, context?: HttpContext): Observable<ResponseTemplateDto> {
    return this.getStaffTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseTemplateDto>): ResponseTemplateDto => r.body)
    );
  }

  /** Path part for operation `getCustomerTemplates()` */
  static readonly GetCustomerTemplatesPath = '/documents/templates/{customerId}';

  /**
   * Get templates applicable for a customer (in customer context)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerTemplates$Response(params: GetCustomerTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseTemplateDto>> {
    return getCustomerTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Get templates applicable for a customer (in customer context)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerTemplates(params: GetCustomerTemplates$Params, context?: HttpContext): Observable<ResponseTemplateDto> {
    return this.getCustomerTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseTemplateDto>): ResponseTemplateDto => r.body)
    );
  }

  /** Path part for operation `getAllChecklists()` */
  static readonly GetAllChecklistsPath = '/documents/checklists';

  /**
   * Get all checklists as zip file.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllChecklists()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllChecklists$Response(params?: GetAllChecklists$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentLinkDto>> {
    return getAllChecklists(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all checklists as zip file.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllChecklists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllChecklists(params?: GetAllChecklists$Params, context?: HttpContext): Observable<DocumentLinkDto> {
    return this.getAllChecklists$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentLinkDto>): DocumentLinkDto => r.body)
    );
  }

  /** Path part for operation `deleteTemplate()` */
  static readonly DeleteTemplatePath = '/documents/templates/{id}';

  /**
   * Delete a template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTemplate$Response(params: DeleteTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTemplate(params: DeleteTemplate$Params, context?: HttpContext): Observable<void> {
    return this.deleteTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
