/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TemplateDto } from '../../models/template-dto';

export interface UploadNationwideTemplate$Params {

/**
 * the document type id
 */
  documentTypeId: number;

/**
 * name of a document
 */
  name: string;

/**
 * the field of care id
 */
  fieldOfCareId: number;
      body?: {

/**
 * the file to be uploaded
 */
'data': Blob;
}
}

export function uploadNationwideTemplate(http: HttpClient, rootUrl: string, params: UploadNationwideTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<TemplateDto>> {
  const rb = new RequestBuilder(rootUrl, uploadNationwideTemplate.PATH, 'post');
  if (params) {
    rb.query('documentTypeId', params.documentTypeId, {});
    rb.query('name', params.name, {});
    rb.query('fieldOfCareId', params.fieldOfCareId, {});
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TemplateDto>;
    })
  );
}

uploadNationwideTemplate.PATH = '/documents/templates/nationwide';
